import { List, Map } from 'immutable';

import { renderTextOnly } from '../components/markup/markupNode.helpers';
import { prefixIdInArticle } from '../components/markup/markupValidate';

// Check if element is an accordion (legacy or otherwise)
// We don't import `hasMarkupLegacyAccordion` since that will screw up builds due to circular dependencies
const isAccordion = (node) =>
	(node.tag === 'ul' && node.attributes?.className === 'acc') || // either legacy-accordion...
	(node.tag === 'div' && node.attributes?.['data-dop-acc'] === 'group'); // ...or new one

export const getHeadersFromContent =
	(results) =>
	(content = List()) =>
		content.reduce((memo, element) => {
			// only take up H2's in the actual text, don't include any from an accordion
			if (isAccordion(element.toJS())) return memo;

			return element.get('tag') === 'h2'
				? memo.push(element)
				: getHeadersFromContent(memo)(element.get('children'));
		}, results);

const getTextListFromChildren = (result = List(), children = List()) =>
	children.reduce(getTextListFromNode, result); //eslint-disable-line no-use-before-define

const getTextListFromNode = (result = List(), node = Map()) =>
	node.has('text')
		? result.push(node.get('text', ''))
		: getTextListFromChildren(result, node.get('children'));

export const getTextFromNode = (node) =>
	getTextListFromNode(List(), node).join('');

export const getTextFromChildren = (children = List()) =>
	children.map(getTextFromNode).join('');

export const getIdForH2 = (node) => {
	const nodeId = node.attributes?.id;

	if (nodeId != null && nodeId.length > 0) {
		return nodeId;
	}

	const text = renderTextOnly([node]);
	const id = prefixIdInArticle(text);

	return id;
};
