export const zIndexClosedFilterTab = 0;
export const zIndexOpenedFilterTab = 1;

export const zIndexSideScroll = 1;
export const zIndexSideScrollAfter = 2;
export const zIndexFormErrorMessage = 2;
export const zIndexFeedbackBorder = 2;
export const zIndexFilterTypeHeader = 3;
export const zIndexAutocompleteSuggestionList = 3;
export const zIndexToTop = 3;
export const zIndexStickyFilter = 4;
export const zIndexFeedback = 4;
export const zIndexFilterClearAllWrapper = 4;
export const zIndexBrowseBackButtonWrapper = 5;
export const zIndexNavigationBar = 6;
export const zIndexDialog = 8;
export const zIndexLoadingOpacity = 8;

export const zIndexSideOverlay = 9;
export const zIndexCardOverlay = 9;
export const zIndexRechtsvormenModal = 10;
export const zIndexRechtsvormenPane = 11;
export const zIndexCookieAlert = 12;

export const zIndexEditorWidget = 20;
